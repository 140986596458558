import * as React from 'react';
import { Link } from 'gatsby';

import SectionHeading from '../components/SectionHeading';
import Container from '../components/Container';

const NotFoundPage = () => {
  return (
    <main>
      <Container>
        <SectionHeading>Page not found</SectionHeading>
        <p>
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </Container>
    </main>
  );
};

export default NotFoundPage;
